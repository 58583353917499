import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    ContainerData:{
        padding:"30px",
        backgroundColor:"antiquewhite"
    },
    HealthcareMailingListText:{
        fontSize:"15px"
    },
    Image:{
        height:"auto",
        width:"auto",
        padding:"0px",
        // maxWidth:"100%",
        // maxHeight:"70%"
    },
    HeadingText:{
        fontSize:"23px"
    },
    H3Tag:{
        // margin:"0px 30px 30px 30px",
        fontSize:"26px"
    },
    SubText:{
        fontSize:"20px",
        //  paddingLeft:"17px"
    },
    SubTextFirst:{
        fontSize:"20px",
        // paddingLeft:"17px"
        paddingTop:"0px"
    }
}))