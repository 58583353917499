
// import React, { useState, Component } from 'react'
// import Logo from '../../Images/Logo.jpeg'
// import { Link } from 'react-router-dom'
// import 'react-dropdown/style.css';
// import useStyles from "./styles";
// import DropDown from './ServiceDropDown/DropDown'
// import DataDropDown from './OurDataDropDown/DataDropDown'
// import NewLogo from '../../Images/NewLogo.jpeg'
// import './Navbar.css';


// export default class Navbar extends Component {

//     state = {
//         isOpen: false
//     }

//     handleClick = () => {
//         this.setState({
//             isOpen: !this.setState.isOpen
//         })
//     }


//     render() {

//         return (
//             <div >
//                 <nav >
//                     <div className="logoBtn">
//                         <div className="Logo">
//                             {/* <Link to="/Blogs"> <img src={NewLogo} className={classes.LogoImage} alt="" /></Link> */}
//                             <a to="/Blogs"> <img src={NewLogo} className="LogoImage" className='logo' alt="" /></a>

//                         </div>
//                         {/* <div className="navigation-menu"> */}
//                         <div className="btn" style={{ cursor: "pointer" }} onClick={this.handleClick}>
//                             <div className="bar1"></div>
//                             <div className="bar2"></div>
//                             <div className="bar3"></div>
//                         </div>
//                     </div>
//                     {/* <div className="navigation-bar-menu"> */}
//                     <ul className={this.state.isOpen ? 'showNav' : 'undefined'}>
//                         <li>
//                             <a>
//                                 <Link to="/" className="NaveItems" href="#">Home </Link>
//                             </a>
//                         </li>
//                         <li>
//                             <a>
//                                 <Link to="/AboutUs" className="NaveItems" href="#">About Us </Link>
//                             </a>
//                         </li>
//                         <li>
//                             <a>
//                                 <Link to="/OurServices" className="NaveItems" href="#">Our Services</Link>
//                             </a>
//                         </li>
//                         <li>
//                             <a>
//                                 <Link to="/HowItsWorks" className="NaveItems" href="#">How It Works </Link>
//                             </a>
//                         </li>
//                         <li>
//                             <a>
//                                 <Link
//                                     className="NaveItems"
//                                     href="#"
//                                 // onClick={closeMobileMenu}
//                                 >
//                                     Industries
//                          </Link>
//                                 { true && <DataDropDown />}
//                             </a>
//                         </li>
//                         <li>
//                             <a>
//                                 <Link to="/ContactUs" className="NaveItems" href="#">Contact Us</Link>
//                             </a>
//                         </li>

//                         {/* <li>
//                             <a>
//                                 <Link  to="/Testimonials" className="NaveItems" href="#">Testimonials</Link>
//                             </a>
//                         </li>
//                          */}
//                     </ul>
//                     {/* </div> */}
//                     {/* </div> */}

//                 </nav>
//             </div>
//         )
//     }
// }


import React, { useState } from 'react'
import Logo from '../../Images/Logo.jpeg'
import { Link } from 'react-router-dom'
import 'react-dropdown/style.css';
import useStyles from "./styles";
import DropDown from './ServiceDropDown/DropDown'
import DataDropDown from './OurDataDropDown/DataDropDown'
import NewLogo from '../../Images/NewLogo.jpeg'
import './Navbar.css';

export default function Navbar() {
    // const classes = useStyles();
    const [click, setClick] = useState(false)
    const [dropdown, setDropDown] = useState(false)
    const [datadropdown, setDataDropDown] = useState(false)
    const handleClick = () => setClick(!click)
    const [open, isOpen] =useState(false)




    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropDown(false);
            setDataDropDown(false);
        } else {
            setDropDown(true)
            setDataDropDown(true)
        };
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropDown(true);
            setDataDropDown(true);
        } else {
            setDropDown(false)
            setDataDropDown(false)
        };
    };

    return (
        <div >
            <nav >
                <div className="logoBtn">
                    <div className="Logo">
                        <a to="/Blogs"> <img src={NewLogo} className="LogoImage" className='logo' alt="" /></a>
                    </div>

                    <div className="btn" style={{ cursor: "pointer" }} onClick= {() => handleClick(true)}>
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div>
                </div>

                <ul className={isOpen ? 'showNav' : 'undefined'}>
                    <li>
                        <a>
                            <Link to="/" className="NaveItems" href="#">Home </Link>
                        </a>
                    </li>
                    <li>
                        <a>
                            <Link to="/AboutUs" className="NaveItems" href="#">About Us </Link>
                        </a>
                    </li>
                    <li>
                        <a>
                            <Link to="/OurServices" className="NaveItems" href="#">Our Services</Link>
                        </a>
                    </li>
                    <li>
                        <a>
                            <Link to="/HowItsWorks" className="NaveItems" href="#">How It Works </Link>
                        </a>
                    </li>
                    <li
                     onMouseEnter={onMouseEnter}
                     onMouseLeave={onMouseLeave}
                     >
                        <a>
                        <Link 
                        to="/industriesMobile"
                            className="NaveItems"
                            href="#"
                        // onClick={closeMobileMenu}
                        >
                            Industries 
                         </Link>
                        {datadropdown && <DataDropDown />}
                        </a>
                    </li>
                    <li>
                        <a>
                            <Link to="/ContactUs" className="NaveItems" href="#">Contact Us</Link>
                        </a>
                    </li>

                    {/* <li>
                            <a>
                                <Link  to="/Testimonials" className="NaveItems" href="#">Testimonials</Link>
                            </a>
                        </li>
                         */}
                </ul>

            </nav >
        </div >
    )
}
