
import React, { Component } from 'react'
import useStyles from "./styles";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


import axios from "axios"


const styles = theme => ({
    RequestTitle: {
        fontSize: "25px !important",
        paddingTop: "20px",
        color: "midnightblue"
    },
    PersonalDetails: {
        textAlign: "left",
        paddingLeft: "30px",
        fontSize: "20px",
        color: "dimgrey"
    },
    RequestSubTitle: {
        color: "darkorange",
        paddingBottom: "20px",
        fontSize: "16px",
        textAlign: "center"
    },
    SubmitButton: {
        marginTop: "40px"
    }
})


class Request extends Component {

    state = {
        formData: {
            email: '',
            password: '',
            name: "",
            LastName:"",
            number: "",
            email: "",
            site:"",
            comments: " ",
            sent: false,

        },
        submitted: false,
    }

    constructor(props){
    	super(props);
    	this.handleSubmit=this.handleSubmit.bind(this);
    }
    handleSubmit(e){
        this.refs.form.reset();
        }


    //Handel inputs


    handelName = (e) => {
        this.setState({
            name: e.target.value
        })

    }

    handelLastName = (e) => {
        this.setState({
            LastName: e.target.value
        })

    }


    handelNumber = (e) => {
        this.setState({
            number: e.target.value
        })

    }

    handelEmail = (e) => {
        this.setState({
            email: e.target.value
        })

    }

    handelsite = (e) => {
        this.setState({
            site: e.target.value
        })

    }


    handelcomments = (e) => {
        this.setState({
            comments: e.target.value
        })

    }
    //End of handel inputs


    formSubmit = (event) => {
        event.preventDefault();
        let data = {
            name: this.state.name,
            LastName:this.state.LastName,
            number: this.state.number,
            email: this.state.email,
            site: this.state.site,
            comments: this.state.comments,
        }
        console.log(data)
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });

        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
        });

        axios.post('/api/forma', data)
            .then(res => {
                this.setState({
                    sent: true,
                })
                // }, this.resetForm())
            }).catch(() => {
                console.log('message not sent')
            })
        console.log({ data })
    }


    //For resenting intial data

    resetForm = () => {
        this.setState({
            name: "",
            LastName:"",
            number: "",
            email: "",
            site:" ",
            comments: " ",
        })

        setTimeout(() => {
            this.setState({
                sent: true,
            })
        }, 3000)
    }

    render() {
        var { classes } = this.props;
        const { formData, submitted } = this.state;

        return (
            <div className="contact-block">
                <Typography className={classes.RequestTitle}>Get in touch with us....</Typography>
                <Divider style={{ margin: "20px" }} />
                <Typography className={classes.RequestSubTitle}>We would love to hear from you! Please fill out this form and we will get in touch with you shortly.</Typography>
                {/* <div>
                    <Typography className={classes.PersonalDetails}><b>Personal Details</b></Typography>
                </div> */}
                <ValidatorForm ref="form"
                    onSubmit={this.handleSubmit}
                    ref="form"
                >
                    <div style={{ padding: "15px" }}>
                        <div className="form-group">
                            <Typography className={classes.PersonName}>First Name *</Typography>
                            <TextValidator
                            ref='firstName'
                                name="name"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className={classes.TextField}
                                variant="outlined"
                                style={{ width: "85%" }}
                                value={this.state.name}
                                onChange={this.handelName}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <Typography className={classes.PersonName}>Last Name </Typography>
                            <TextValidator
                                name="name"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className={classes.TextField}
                                variant="outlined"
                                style={{ width: "85%" }}
                                value={this.state.LastName}
                                onChange={this.handelLastName}
                                required
                            />
                        </div>


                        <div className="form-group">
                            <Typography className={classes.PersonName}>Contact Number</Typography>
                            <TextValidator
                                name="number"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className={classes.TextField}
                                variant="outlined"
                                style={{ width: "85%" }}
                                value={this.state.number}
                                onChange={this.handelNumber}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <Typography className={classes.PersonName}>Email *</Typography>
                            <TextValidator
                                name="email"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className={classes.TextField}
                                variant="outlined"
                                style={{ width: "85%" }}
                                value={this.state.email}
                                onChange={this.handelEmail}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                            />
                        </div>

                        <div className="form-group">
                            <Typography className={classes.PersonName}>Website</Typography>
                            <TextValidator
                                name="Website"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className={classes.TextField}
                                variant="outlined"
                                style={{ width: "85%" }}
                                value={this.state.site}
                                onChange={this.handelsite}
                            />
                        </div>
                        <div className="form-group">
                            <Typography className={classes.PersonName}>Comments</Typography>
                            <TextValidator
                                // label="Multiline"
                                multiline
                                rows={6}
                                name="comments"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                className={classes.TextField}
                                variant="outlined"
                                style={{ width: "85%" }}
                                value={this.state.comments}
                                onChange={this.handelcomments}
                            />
                        </div>
                    </div>
                    <div style={{ padding: "0px 30px 30px 30px" }}>
                        {/* <div className={this.state.sent=== true ? "msg msgAppear" : null}> Thank you for connecting with us:- our panel will connecting you soon</div> */}
                        <div className="btn-submit">
                            <Button variant="contained"
                                color="primary"
                                // className={classes.SubmitButton}
                                style={{ width: "300px" }}
                                type="submit"
                                disabled={submitted}
                                onClick={this.formSubmit}
                            >
                                <b> {
                                    (submitted && 'Your form is submitted!')
                                    || (!submitted && 'Submit')
                                }
                                </b>
                            </Button>
                        </div>
                        <Typography style={{ color: "darkgray", textAlign: "center", fontSize: "12px", paddingTop: "10px" }}>Your Privacy Matters!</Typography>
                    </div>
                </ValidatorForm>
            </div>
        )
    }
}
Request.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Request);
