import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    ContainerData:{
        padding:"20px",
        width:"100%",
        backgroundColor:"antiquewhite"
    },
    HealthcareMailingListText:{
        fontSize:"15px"
    },
    Image:{
        height:"auto",
        width:"auto",
        padding:"0px",
        // maxWidth:"100%",
        // maxHeight:"95%"
    },
    // HeadingText:{
    //     fontSize:"24px"
    // },
    H3Tag:{
        // margin:"0px 30px 30px 30px",
        fontSize:"22px"
    },
    SubText:{
        fontSize:"20px",
        //  paddingLeft:"17px"
    },
    SubTextFirst:{
        fontSize:"20px",
        // paddingLeft:"17px"
        paddingTop:"0px"
    }
}))