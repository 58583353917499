import React from 'react'
import HealthCareIndustries from './HealthCareIndustries'
import TechnologyMobile from './Technology'
import CustomMailingList from './Custom' 
import EducationMailingList from './educational'
import BankingFinanceMailing from './BankingAndFinance'
import AutomobileMailing from './AutoMobile'
import ManufacturingMailingList from './Manufacturing'
import OilGasMailingList from './OilAndGas'
import ConstructionMailingList from './Constraction'
import RetailMailingList from './Retail'

export default function industriesMobile() {
    return (
        <div>
            <HealthCareIndustries />
            <TechnologyMobile />
            <CustomMailingList />
            <EducationMailingList />
            <BankingFinanceMailing />
            <AutomobileMailing />
            <ManufacturingMailingList />
            <OilGasMailingList />
            <ConstructionMailingList />
            <RetailMailingList />
        </div>
    )
}
